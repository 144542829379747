import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HashRouter as Router, Redirect, Route, Switch, Link } from 'react-router-dom';
// import PrivateRoute from 'react-private-route'
import { ACTIONS } from '../../../utils/Constants';
import UpdateProfile from '../../Authentication/components/UpdateProfile/updateProfile';
import ChangePassword from '../../Authentication/components/ChangePassword/changePassword';
import logo from '../../../images/logo.png';
import nabelllogo from '../../../images/nabell.png';
import thklogo from '../../../images/thk.png';
import SideTabNavigation from '../../Dashboard/components/Dashboard/SideTabNavigation';
import { Container } from 'react-bootstrap';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import SavedPartNumber from '../../Savedpartno/Component/SavedPartNumber';
import Access from '../../Accesscalculator/Access';
import Unauthorized from '../../Authentication/components/Unauthorized/Unauthorized'
import ComingSoon from '../../Accesscalculator/ComingSoon'; 
import Manageusers from '../../ManageUsersNew/components/manageUser/manageUser';
import CalculatorList from "../../ManageCalculator/Components/CalculatorList";
import ManageAttribute from "../../ManageAttributes/components/ManageAttribute/ManageAttribute";
import ManageManufacturer from "../../ManageManufacturers/components/ManageManufacturer/ManageManufacturer";
import Auth from '../../../utils/Auth';
import ManageRegisteredCustomers from '../../ManageCustomers/components/manageRegisteredCustomer/manageRegisteredCustomer';
import ManageGuestCustomers from '../../ManageCustomers/components/manageGuestCustomer/manageGuestCustomer';
import ManageRole from '../../ManageRoles/components/ManageRole/ManageRole';
import Footer from '../../Common/components/Footer/Footer'
import ManagePricing from '../../ManagePricing/components/managePricing/managePricing';
import PricingHistory from '../../ManagePricing/components/managePricing/pricingHistory';
import CalculatorOutput from '../../Calculators/components/calculators/CalculatorOutput';
import Railcalculator from '../../Calculators/components/calculators/Railcalculator';
import Partnumbercalculator from '../../Calculators/components/calculators/Partnumbercalculator';
import KrSeriesbellows from '../../Calculators/components/calculators/KrSeriesbellows';
import calculatorOutput from '../../Calculators/CalculatorOutputReducer';
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../component/IdleTimeOutModal'
import { getURLAccessible } from '../../Authentication/AuthenticationApiActions';
import PageNotFound from '../../ErrorPages/PageNotFound';
import Navigation from '../../Authentication/components/Navigation/Navigation';

var ACTIONSURL = [
    '/pagenotfound',
    '/page403',
    '/error-500',
    '/signin',
     '/admin/signin',
     '/',
    '/registerCustomer',
    '/personalinformation',
    '/changepassword',
     '/manageusers',
     '/manageregisteredcustomers',
     '/manageguestcustomers',
     '/manageroles',
     '/managemanufacturers',
    '/managemasterattributes',
     '/savedpartnumbers',
     '/home',
    '/calculatorlist',
    '/setpassword',
    '/setadminpassword',
    '/managepricings',
    '/pricinghistory',
    '/calculatoroutput',
    '/comingsoon',
    '/Railcalculator' 
];

const auth = new Auth();
var timeoutsec = 0;
var IsAccess = false;
const NoMatchPage = () => {
    return (
      <h3>404 - Not found</h3>
    );
  };
class Layout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            component: window.location.pathname,
            isLoggedIn: this.props.isLoggedIn,
            logo: logo,
            nabelllogo: nabelllogo,
            thklogo: thklogo,
            
            timeout:1000 * 60 * 10, //10 min
            //timeout: 1000 * 60 * 15,//15 min
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false
        }

        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
        
    }

    _onAction(e) {
        //console.log('user did something', e)
        //this.setState({isTimedOut: false})
      }
     
      _onActive(e) {
        //console.log('user is active', e)
        //this.setState({isTimedOut: false})
      }
     
      _onIdle(e) {
        console.log('user is idle', e)
        const isTimedOut = this.state.isTimedOut
        if (isTimedOut) {
            // this.props.history.push('/')
            auth.logout();
            timeoutsec = timeoutsec+1;
        } else {
          this.setState({showModal: true})
          this.idleTimer.reset();
          this.setState({isTimedOut: true})
        }
      }

      handleLogout(){
          auth.logout();
      }
      handleClose(){
        this.setState({showModal: false})
      }
    renderComponent(componentName) {
        this.setState({
            component: componentName
        })
        Object.assign(this.state, {});
    }

    doSomethingBeforeUnload = (event) => { 
        //auth.logout();
    }
    
    componentDidMount(){
        if (this.props.isLoggedIn) {
            window.addEventListener('storage',this.LocalStorageChange,false);
        }
        //window.addEventListener('beforeunload', this.doSomethingBeforeUnload);
    }
    
    componentWillUnmount() {
        //window.removeEventListener('beforeunload', this.doSomethingBeforeUnload);
    }
    logoutevent =(e)=>{
        if(window.localStorage.getItem("isAdmin") == "true"){
             auth.setUserType("true")
           }
           auth.otherTabLogout();
           localStorage.setItem("isAdmin", "true");
    }
    
    LocalStorageChange =(e)=>{
        //console.log("Auth : " + e.key)
        if(e.key == "nabellAuth" && e.oldValue == "true" && e.newValue=="false"){
            setTimeout(this.logoutevent, 50)
        }
    };

    componentDidUpdate(){
        const  currentLocation = this.props.location.pathname;
    }

    CheckAccess = (url) => {
        if(url!="/unauthorized"){
          getURLAccessible(url).then(response => {
                if(response==false){
                    window.location.href = "/#/unauthorized";
                }
                else{
                    IsAccess=response; 
                }
                return response;
            }, error => {
                
                IsAccess=false;
                return false;
            });
        }
        else{
            return true;
        }
    }

    render() {
        const topheight = {
            padding: '100px 0 30px',
        };
        
        var currentLocation = this.props.location.pathname;

        var found = false;
        if(currentLocation.indexOf("script") != -1){
            found = false;
        }
        for(var i=0;i<ACTIONSURL.length;i++){
            if(currentLocation.toLowerCase() == ACTIONSURL[i].toLowerCase())
            {
                found = true;
            }
        }
        
        if(currentLocation != "" && this.props.location.hash != "")
        {
            found = false;
        }

        if(found == false)
        {
            currentLocation = "/pagenotfound"
            window.location.href="#/pagenotfound"
            window.location.reload();
        }
        if (!this.props.isLoggedIn) {
            return (<Redirect to={{
                pathname: ACTIONS.SIGN_IN,
                state: { from: currentLocation }
            }} />)
        }
		else if(auth.getIsPasswordReset() != "true" && currentLocation != ACTIONS.CHANGE_PASSWORD){
            return (<Redirect to={{
                pathname: ACTIONS.CHANGE_PASSWORD,
                state: { from: currentLocation }
            }} />)
        }
        else {
            return (
                <>
                <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout} />

                <Router>

                <Navigation Logo={this.state.logo}></Navigation>
                    <React.Fragment>
                        <Switch>
                        
                        <Route exact path='/' render={(props) => {
                                return <Access Nlogo={this.state.nabelllogo} thklogo={this.state.thklogo} {...props} pageTitle='Home' />
                            }} />

                            <Route  exact path={ACTIONS.ACCESS_PAGE} render={(props) => {
                                return <Access Nlogo={this.state.nabelllogo} thklogo={this.state.thklogo} {...props} pageTitle='Home' />
                            }} />

                            <Route exact path={ACTIONS.COMING_SOON} render={(props) => {
                                return <ComingSoon {...props} pageTitle='Coming Soon' />
                            }} />


                            <Route exact path="/Railcalculator" render={(props) => {
                                return <CalculatorOutput {...props} pageTitle='Calculator' />
                            }} />
{/* 
                            <Route exact path="/Partnumbercalculator" render={(props) => {
                                 return <Partnumbercalculator {...props} pageTitle='Calculator' />
                            }} />

                            <Route exact path="/KrSeriesbellows" render={(props) => {
                               return <KrSeriesbellows {...props} pageTitle='Calculator' />
                            }} /> */}

                            <Container style={topheight}>
                                {(currentLocation!=='/pagenotfound')?<>
                                <div className="col-lg-12">
                                <div className="row">
                                
                                    <div className="col-lg-3 col-md-4 sidebar">
                                        <SideTabNavigation onLinkClick={this.renderComponent} />
                                    </div>
                                    <div className="col-lg-9 col-md-8">
                                        {/* <Body componentToLoad={this.state.component} /> */}

                                        <Card>

                                            <CardContent>

                                                <Route exact path={ACTIONS.MANAGE_MANUFACTURERS} render={(props) => {
                                                    return <ManageManufacturer menu="*" {...props} pageTitle='Update Manufacturer' />
                                                }} />
                                               
                                                
                                                <Route exact path={ACTIONS.UPDATE_CUSTOMER} render={(props) => {
                                                    return <UpdateProfile {...props} pageTitle='Update Customer' />
                                                }} />

                                                <Route exact path={ACTIONS.CHANGE_PASSWORD} render={(props) => {
                                                    return <ChangePassword {...props} pageTitle='Change Password' />
                                                }} />

                                                <Route exact path={ACTIONS.SAVED_PART_NO} render={(props) => {
                                                    return <SavedPartNumber {...props} pageTitle='Saved Part no' />
                                                }} />

                                                <Route exact path={ACTIONS.MANAGE_USERS} render={(props) => {
                                                    return <Manageusers {...props} pageTitle='Manage Users' />
                                                }} />

                                                <Route exact path={ACTIONS.REGISTERED_CUSTOMERS} render={(props) => {
                                                    return <ManageRegisteredCustomers {...props} pageTitle='Manage Registered Customers' />
                                                }} />

                                                <Route exact path={ACTIONS.GUEST_CUSTOMERS} render={(props) => {
                                                    return <ManageGuestCustomers {...props} pageTitle='Manage Guest Customers' />
                                                }} />

                                                <Route exact path={ACTIONS.MANAGE_PRICING} render={(props) => {
                                                    return <ManagePricing {...props} pageTitle='Manage Pricings' />
                                                }} />
                                                <Route exact path={ACTIONS.PRICING_HISTORY} render={(props) => {
                                                    return <PricingHistory {...props} pageTitle='Pricing History' />
                                                }} />

                                                 <Route exact path={ACTIONS.CALCULATOR_LIST} render={(props) => {
                                                    return <CalculatorList {...props} pageTitle='Manage Calculator' />
                                                }} />

                                               

                                                <Route exact path={ACTIONS.MANAGE_ATTRIBUTE} render={(props) => {
                                                    return <ManageAttribute {...props} pageTitle='Manage Attributes' />
                                                }} />

                                                <Route exact path={ACTIONS.MANAGE_ROLES} render={(props) => {
                                                   return <ManageRole menu="*" {...props} />} }
                                                />
                                                 <Route exact path={ACTIONS.PAGE_403} render={(props) => {
                                                    return <Unauthorized menu="*" {...props} pageTitle='Unauthorized' />
                                                }} />
                                                 
                                                {/* <Route exact path="*" render={(props) => 
                                                   {return <PageNotFound menu="*" {...props} />}} 
                                                /> */}
                                                {/* <Route component={PageNotFound} /> */}
                                            </CardContent>


                                        </Card>
                                    </div>
                                </div>
                                </div>
                                </>
                                :<>
                                 <div className="col-lg-12">
                                <div className="row">
                                <Route exact path={ACTIONS.PAGE_NOT_FOUND} render={(props) => {
                                                    return <PageNotFound menu="*" {...props} pageTitle='Updat' />
                                                }} />
                                </div>
                                </div>
                                </>}
                           

                            </Container>
                     
                        </Switch>
                        <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />

                    </React.Fragment>
                </Router>
                <Footer/>
                
                <div className="loader-parent d-none" id="loaderdiv">
                    <div className="loader loadercenter">
                        <i className="fa fa-spinner fa-spin" />
                    </div>
                </div>
              </>
            )
        }
    }
}

const mapStateToProps = state => {

    return {
        isLoggedIn: state.auth.isLoggedIn
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {}
}

Layout = connect(mapStateToProps, mapDispatchToProps)(Layout);

export default Layout;