import React, { Component, Text, TextInput } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import { TextField } from 'formik-material-ui';
// import { Image, StyleSheet, Text, View, TouchableWithoutFeedback, TextInput } from 'react-native';
import TextField from '@material-ui/core/TextField';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { getPartDetailList, getDefaultValuesForIKnowPartNumberData } from '../../CalculatorOutputApiActions';
import { SafeAnchor, Modal } from 'react-bootstrap';
import calculatorPartNumberOutputSchema from './PartNumberOutputSchema';
import { Collapse, StepIcon } from '@material-ui/core';
import swal from 'sweetalert';
import BellowsByManufacturerDropDown from '../../../Common/components/DropDown/BellowsByManufacturerDropDown';
import SizeByBellows from '../../../Common/components/DropDown/SizeByBellows';
import IKnowPartNumberMaterial from '../../../Common/components/DropDown/IKnowPartNumberMaterial';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Auth from '../../../../utils/Auth';
import Select from '@material-ui/core/Select';
import {APP_DETAILS,SERVER_ACTIONS} from '../../../../utils/Constants';
let auth = new Auth();

class IKnowPartNumberForm extends React.Component {

    constructor(props) {

        super(props);
        this.textPart = React.createRef();
        this.state = {
            id: props.number,
            partNumber: 0,
            calculatorId: props.calculatorId ? props.calculatorId : 0,
            manufacturerId: props.manufacturerId ? props.manufacturerId : 0,
            selectedId: 0,
            allPartDetails: [],
            expanded: 'panel0',
            UserName: auth.getUser(),
            bellowsId: this.props.bellowsId,
            bellowsText: this.props.bellowsText,
            sizeId: this.props.sizeId,
            sizeText: this.props.sizeText,
            HiTemp: "None",
            middleBellows: "None",
            MaxValue: "0",
            MinValue: "0"
        }
        //console.log(this.state.id);
    }

    componentDidMount(props) {
    }

    handleChange = panel => (event, isExpanded) => {
        this.setState({
            expanded: isExpanded ? panel : false
        })
    };

    ExpandPanel = panel => (event, isExpanded) => {
        this.setState({
            expanded: panel
        })
    };

    registercustomer = () => {
        auth.setRegisterComponent(true);
        auth.logout();
    }

    handleChangeBellowsDropdown = (event) => {
        if (event.target.value > 0) {
            getDefaultValuesForIKnowPartNumberData(this.state.manufacturerId, event.target.value).then(response => {
                this.setState({
                    bellowsId: response.BellowId,
                    sizeId: response.SizeId,
                    bellowsText: response.BellowsText,
                    sizeText: response.SizeText,
                    allPartDetails:[],
                    HiTemp : "None"                    
                })
            }, error => {

            });
        }
        else {
            this.setState({
                bellowsId: 0,
                sizeId: 0,
                bellowsText: "",
                sizeText: ""
            })
        }
    }

    getDetails = (partNumber, formAction) => {
        let data = {}
        var Hitemp = "";
        if (partNumber.HiTemp != "None") {
            Hitemp = "-" + partNumber.HiTemp
        }
        else {
            Hitemp = "-";
        }
        var middleBellow = "";
        if (partNumber.middleBellows != "None") {
            middleBellow = "-" + partNumber.middleBellows
        }
        else {
            middleBellow = "-";
        }
        if (partNumber.bellowsText == "") {
            swal("Please select bellows", {
                buttons: {
                    cancel: "OK",
                },
            })
                .then((cancel) => {
                    switch (cancel) {
                        case "Yes":
                            break;
                        default:
                    }
                });
        }
        else if (partNumber.sizeText == "") {
            swal("Please select Size", {
                buttons: {
                    cancel: "OK",
                },
            })
                .then((cancel) => {
                    switch (cancel) {
                        case "Yes":
                            break;
                        default:
                    }
                });
        }
        else if (partNumber.MinValue == "" || partNumber.MinValue < 1) {
            swal("Please enter valid Min length", {
                buttons: {
                    cancel: "OK",
                },
            })
                .then((cancel) => {
                    switch (cancel) {
                        case "Yes":
                            break;
                        default:
                    }
                });
        }
        else if (partNumber.MaxValue == "" || partNumber.MaxValue < 1) {
            swal("Please enter valid Max length", {
                buttons: {
                    cancel: "OK",
                },
            })
                .then((cancel) => {
                    switch (cancel) {
                        case "Yes":
                            break;
                        default:
                    }
                });
        }
        else {

            data.partNumber = partNumber.bellowsText + '-' + partNumber.sizeText + '-' +
                partNumber.MinValue + '-' + partNumber.MaxValue + '' + middleBellow + '' + Hitemp;
            //partNumber
            data.calculatorId = this.state.calculatorId;
            this.setState({
                partNumber: partNumber
            })
            formAction.setSubmitting(true);
            getPartDetailList(data).then(response => {
                this.setState({ allPartDetails: response });
                formAction.setSubmitting(false);
            }, error => {
                formAction.setSubmitting(false);
                this.setState({ allPartDetails: [] });
            });
        }
    }

    renderTableHeader = (OPParam) => {

        if (OPParam != undefined) {
            return OPParam.map((data) => {

                const { DisplayName } = data;
                return (
                    <TableCell>{DisplayName}</TableCell>
                )
            })
        }
    }

    renderTableData = (OPParam) => {

        if (OPParam != undefined) {
            return OPParam.map((data) => {

                const { DisplayName, Value } = data;
                return (
                    <TableCell>{Value}</TableCell>
                )
            })
        }
    }

    renderSecondTableData = (OPPricing, Message, MessageOne) => {
        if (OPPricing != undefined) {
            //if (Message == "") {
            if (MessageOne == "") {
                return OPPricing.map((data) => {
                    const { DisplayName, Value } = data;
                    return (
                        <TableCell>{Value}</TableCell>
                    )
                })
            }
            else {
                return (<TableCell colSpan={OPPricing.length} style={{ color: "red" }}>{MessageOne}</TableCell>)
            }
            // }
            // else {
            //     return (<TableCell colSpan={OPPricing.length} >{Message}</TableCell>)
            // }

            // return OPPricing.map((data) => {

            //     const { DisplayName, Value } = data;
            //     return (
            //         <TableCell>{Value}</TableCell>
            //     )
            // })
        }
    }

    removethisdiv = (e, div) => {
        // var pnform = document.getElementsByClassName("Partnumberform");
        // if(pnform.length > 1)
        {
            var element = document.getElementById(div);
            element.parentNode.remove();
        }
        //else
        {
            //swal("One Block is required");
        }
    }

    renderSecondTableHeader = (OPPricing) => {
        if (OPPricing != undefined) {
            return OPPricing.map((data) => {

                const { DisplayName, Value } = data;
                return (
                    <TableCell>{DisplayName}</TableCell>
                )
            })
        }
    }

    render() {
        let allPartDetails = [];
        allPartDetails = this.state.allPartDetails;
        let OPPricing, OPParam, ImagePath, MessageOne, Message, PartNumber, FormulaError, OPParamLenght

        let partDetailsArray = [];
        var LoginLimitExceed = false;
        if (allPartDetails != undefined) {

            LoginLimitExceed = allPartDetails.LoginLimitExceed;
            OPPricing = allPartDetails.OPPricing;
            OPParam = allPartDetails.OPParam;
            if (OPParam != undefined) {
                OPParamLenght = OPParam.length;
            }
            else {
                OPParamLenght = 0;
            }
            ImagePath = allPartDetails.ImagePath;

            if (ImagePath == undefined) {
                ImagePath = SERVER_ACTIONS.API + "/Media/OutputImages/IKnowLength/JSH45-Bare-Rail.png"
            }
            MessageOne = allPartDetails.MessageOne;
            Message = allPartDetails.Message;
            PartNumber = allPartDetails.PartNumber;
            FormulaError = allPartDetails.FormulaError;
        }
        return (
            <Formik
                onSubmit={this.getDetails}
                initialValues={this.state}
                enableReinitialize={true}
            //validationSchema={calculatorPartNumberOutputSchema}
            >
                {({ values, status, isSubmitting }) => (
                    <Form className="Partnumberform">

                        <div id={"Remove" + this.state.id} className="dotted-border">
                            <ExpansionPanel className="" expanded={this.state.expanded === 'panel' + this.state.id} onChange={this.handleChange('panel' + this.state.id)}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >

                                    <div onClick={event => event.stopPropagation()} className="part-number-form calcOutput">
                                        {/* {this.state.id + ")" + " "} */}
                                        <div className="row product-line row-fields">
                                            <div className="leftelement col-lg-2 col-md-6 col-sm-6">
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Bellows</InputLabel>
                                                    <BellowsByManufacturerDropDown enabled={true}
                                                        manufacturerId={this.state.manufacturerId}
                                                        selectedValue={this.state.bellowsId}
                                                        activeOnly={true}
                                                        onBellowChange={e => {
                                                            // this.setState({
                                                            //     sizeId: 0, sizeText: "",
                                                            //     bellowsText: e.currentTarget.outerText,
                                                            //     bellowsId: e.target.value,
                                                            // })
                                                            this.handleChangeBellowsDropdown(e);
                                                        }}
                                                    />
                                                </FormControl>
                                            </div>

                                            <div className="col-lg-1 col-md-6  col-sm-6">
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Size</InputLabel>
                                                    <SizeByBellows enabled={true} name={"size"+this.state.id} id={"size"+this.state.id}
                                                        manufacturerId={this.state.manufacturerId}
                                                        bellowsId={this.state.bellowsId}
                                                        selectedValue={this.state.sizeId}
                                                        activeOnly={true}
                                                        onSizeChange={e => {
                                                            this.setState({
                                                                allPartDetails:[],
                                                                sizeText: e.currentTarget.outerText,
                                                                sizeId: e.target.value,
                                                            })
                                                        }}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className="col-lg-2 col-md-6  col-sm-6">
                                                <div className="no-print">
                                                    <Field type="text"
                                                        onBlur={e => {
                                                            this.setState({
                                                                allPartDetails:[],
                                                                MinValue: e.target.value
                                                            })
                                                        }}
                                                        name="min"
                                                        placeholder="Min"
                                                        component={TextField}
                                                        id="standard-required"
                                                        label="Min"
                                                        margin="normal"
                                                        defaultValue={this.state.MinValue}
                                                        fullWidth
                                                        type="number"
                                                        min={0}
                                                    /></div>
                                                <div className="print-ele d-none"><div class="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth" field="[object Object]" form="[object Object]" min="0"><label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled" data-shrink="true" for="standard-required" id="standard-required-label">Min</label><div class="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl"><label>{this.state.MinValue}</label></div></div></div>
                                            </div>
                                            <div className="col-lg-2 col-md-6  col-sm-6">
                                                <div className="no-print">
                                                    <Field type="text"
                                                        onBlur={e => {
                                                            this.setState({
                                                                allPartDetails:[],
                                                                MaxValue: e.target.value
                                                            })
                                                        }}
                                                        name="max"
                                                        placeholder="Max"
                                                        component={TextField}
                                                        id="standard-required"
                                                        label="Max"
                                                        margin="normal"
                                                        type="number"
                                                        defaultValue={this.state.MaxValue}
                                                        fullWidth
                                                        min={0}
                                                    />
                                                </div>
                                                <div className="print-ele d-none"><div class="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth" field="[object Object]" form="[object Object]" min="0"><label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled" data-shrink="true" for="standard-required" id="standard-required-label">Min</label><div class="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl"><label>{this.state.MaxValue}</label></div></div></div>
                                            </div>
                                            <div className="col-lg-1 col-md-6 pr-1 pl-1  col-sm-6">
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Middle</InputLabel>
                                                    <Select component="select" defaultValue={this.state.middleBellows}
                                                        labelId="demo-simple-select-label" name="middlebellows"
                                                        id="demo-simple-select"
                                                        onChange={e => {
                                                            this.setState({
                                                                allPartDetails:[],
                                                                middleBellows: e.target.value,
                                                            })
                                                        }}
                                                    >
                                                        <MenuItem value="None">None</MenuItem>
                                                        <MenuItem value="M">M</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="col-lg-2 col-md-6  col-sm-6">
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Hi-Temp</InputLabel>
                                                    <IKnowPartNumberMaterial enabled={true}
                                                        manufacturerId={this.state.manufacturerId}
                                                        selectedValue={this.state.HiTemp}
                                                        activeOnly={true}
                                                        onMaterialChange={e => {
                                                            this.setState({
                                                                allPartDetails:[],
                                                                HiTemp: e.target.value,
                                                            })
                                                        }}
                                                    />
                                                </FormControl>

                                            </div>
                                            <div className="col-lg-2 col-md-6  col-sm-6">
                                                <Button color="primary" type="submit" className="submit-btn"
                                                    onClick={event => { event.stopPropagation() }, this.ExpandPanel('panel' + this.state.id)}>
                                                    Get Details
                    </Button>
                                            </div>
                                        </div>
                                    </div>
                                </ExpansionPanelSummary>

                                <ExpansionPanelDetails>
                                    {(OPParamLenght > 0) ? (<table className="responsiveTable"><tr><td className="float-left">Bellows Parameters</td>
                                    <td className="float-right">
                                        {/* Part No.:{PartNumber} */}
                                        </td></tr></table>)
                                        : (<></>)}
                                    <div className="responsiveTable" id={"details"+this.state.id}>
                                        {(OPParamLenght > 0) ? (
                                            <Table>
                                                <TableHead className="grayheader">
                                                    {this.renderTableHeader(OPParam)}
                                                </TableHead>
                                                <TableRow>
                                                    {this.renderTableData(OPParam)}
                                                </TableRow>
                                                <TableBody>

                                                </TableBody>
                                            </Table>
                                        ) : (<></>)}
                                        <label style={{ color: "red" }}>{FormulaError}</label>
                                        <Table>
                                            <TableHead className="grayheader">

                                                {this.renderSecondTableHeader(OPPricing)}
                                            </TableHead>
                                            <TableRow>
                                                {this.renderSecondTableData(OPPricing, Message, MessageOne)}
                                            </TableRow>
                                            <TableBody>

                                            </TableBody>
                                        </Table>
                                        {(LoginLimitExceed == false || LoginLimitExceed == undefined) ? (<></>) :
                                            //(<p style={{color: "red"}}>  Dear {this.state.UserName}, your guest login count limit is exceeded, to get know about the pricing of specific bellows, please <a onClick={this.registercustomer} className="register" style={{"color":"white"}}>register</a>  or contact on <a href="tel:800-650-2015">{800-650-2015}</a></p>)
                                            (<p style={{ color: "red" }}>  Dear {this.state.UserName}, Are you a Distributor or Manf. Rep.? Become an Authorized User online
                                            or call <a href={`tel:${APP_DETAILS.CONTACT_NUMBER}`}>{APP_DETAILS.CONTACT_NUMBER}</a>.<br></br>
Authorized Users receive multi level pricing, priority shipping and more.To Apply: <a onClick={this.registercustomer} className="register" style={{ "color": "white" }}>Register Here</a> </p>)
                                        }
                                    </div>
                                    <div className="calc-image">
                                        <div hidden id="PartNumber">{PartNumber}</div>
                                        <img className="img-calculator" src={ImagePath} alt="output-image" />
                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>

                        </div>
                    </Form>
                )}
            </Formik>
        )
    }
}
// const mapProps = (state) => {

//     return {
//         allPartDetails: state.calculatorOutput.allPartDetails
//     }
// };

// const mapDispatchToProps = (dispatch, ownProps) => {
//     return {
//         getPartDetails: (data) => {
//             return new Promise((resolve, reject) => {
//                 dispatch(getPartDetailList(data))
//                     .then((response) => {
//                         resolve(response);
//                     })
//                     .catch((error) => {
//                         reject(error);
//                     });
//             });
//         }
//     };
// };

// export default connect(mapProps, mapDispatchToProps)(PartNumberForm);
export default IKnowPartNumberForm;