import * as Yup from "yup";
import { REGEX, REGEX_ERROR } from "../../../../utils/Constants";

const ManufacturerFormSchema = Yup.object().shape({

        name: Yup.string()
        .matches(REGEX.ALPHANUMERIC_WITH_SPACE, REGEX_ERROR.ALPHANUMERIC_WITH_SPACE_ERROR)
        .required('Please Enter  Name'),
        display_name: Yup.string()
        .matches(REGEX.ALPHANUMERIC_WITH_SPACE, REGEX_ERROR.ALPHANUMERIC_WITH_SPACE_ERROR)
        .required('Please Enter Display Name'),
       



});

export default ManufacturerFormSchema;