import React, { Component } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from 'react-bootstrap/Pagination';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import EditGuestCustomer from './editGuestCustomer';
import { getGuestCustomerList, deleteCustomerFromList, toggleStatusCustomertItem } from '../../ManageCustomersApiActions';
import { connect } from 'react-redux';
import './addGuestCustomer.css';
import { SafeAnchor } from 'react-bootstrap';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import Moment from 'moment';
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
const StyledReactTooltip = styled(ReactTooltip)`
  background-color: white !important;
  color: black !important;
  
  &:after {
    border-top-color: white !important;
  }
`;

class ManageGuestCustomers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            active: false,
            checked: true,
            defaultPageSize: 10,
            PageNumber: 1,
            totalPages: 1,
            pageheading: 'Manage Guest Customers',
            data: {},
            editcustomerModalShow: false,
            update:true
        }
    }

    componentDidMount() {
        this.getGuestCustomersList(this.state.PageNumber);
    }

    getGuestCustomersList(PageNumber) {
        const { defaultPageSize } = this.state;
        this.state.PageNumber = PageNumber;

        if (PageNumber > 0) {
            const query = 'PageNumber=' + PageNumber + '&PageSize=' + defaultPageSize + (this.state.query !== '' ? '&q=' + this.state.query : '');
            this.props.getGuestCustomerList({ query, PageNumber, defaultPageSize }).then(resp => {
                const numberOfRecords = this.props.customers.Data.data.length;

                const totalPages = this.props.customers.Data.totalpages;

                let data = this.props.customers.Data;
                this.setState({
                    numberOfRecords,
                    totalPages,
                    data: data
                });

            }).catch(error => {
                window.location.href="#/page403"
                //window.location.reload();
                });
        }
    }

    handlePageClick = (e, index) => {
        //   this.setState({
        //      PageNumber: index
        //   });
        let PageNumber = index;
        this.getGuestCustomersList(PageNumber);

    }

    deleteCustomer = (user_id, modified_by) => {

        Swal.fire({
            //title: 'Are you sure?',
            text: 'Are you sure want to delete?',
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            showCloseButton: true
          }).then((result) => {
            if (result.value) {
                this.props.deleteCustomer(user_id, modified_by).then(res => {
                                        this.updateList();
                                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              
            }
          })
        // swal("Are you sure want to delete?", {
        //     buttons: {
        //         catch: {
        //             text: "Yes",
        //             value: "Yes",
        //         },
        //         cancel: "Cancel",
        //     },
        // })
        //     .then((value) => {
        //         switch (value) {
        //             case "Yes":
        //                 this.props.deleteCustomer(user_id, modified_by).then(res => {
        //                     this.updateList();
        //                 });
        //                 break;
        //             default:
        //         }
        //     });
    }

    updateList = () => {
        this.setState(Object.assign({}, this.state));
    };
    
    statusChange(user_id, modified_by,nabell_id) {
        this.props.changeStatus(user_id, modified_by,nabell_id).then(res => {
            this.updateList();
        })
    };

    renderPagination = () => {

        let items = [];
        if (this.state.data.data !== [] && this.state.data.data != undefined) {
            // for (let i = 1; i <= this.props.totalPages; i++) {

            //     items.push(<Pagination.Item className={this.state.PageNumber === i ? "active" : ""} onClick={e => this.handlePageClick(e, i)} >{i}</Pagination.Item>);

            // }
            let j=3;
            for (let i = 1; i <= this.state.totalPages; i++) {
                if(this.state.PageNumber>=i && this.state.PageNumber+3<this.state.totalPages){
                    i=this.state.PageNumber;
                    j=this.state.PageNumber+3;
                }
                if(this.state.PageNumber+3>=this.state.totalPages){
                    if(this.state.totalPages-3>0)
                    {if(i<this.state.totalPages-3)
                        i=this.state.totalPages-3;
                        j=i+3;
                    }
                    else if(i==this.state.totalPages){
                        i=this.state.totalPages;
                    }
                    else{
                        if(i<3-this.state.totalPages){
                            i=3-this.state.totalPages;
                        }
                       }
                }
               
                if(i<j || i==this.state.totalPages)
                {
                    items.push(
                        <Pagination.Item
                            className={this.state.PageNumber === i ? "active" : ""}
                            onClick={e => this.handlePageClick(e, i)} >
                            {i}
                        </Pagination.Item>
                    );

                }
              if(i<j && this.state.totalPages-i==1)
                {
                }
                else
               
                 {
                    if(i==this.state.totalPages-1){
                        items.push(<Pagination.Ellipsis />)
                          }
                  }   
            }
            
            return items;
        }
    }

    renderTableData = () => {

        if (this.state.data.data !== [] && this.state.data.data != undefined) {
            return this.state.data.data.map((data) => {
                const { email, customer_id, first_name, last_name, role_name, login_count, last_login, is_active,modified_by } = data //destructuring
                return (
                    
                    login_count <=10  || role_name=='Nabell.com Visitor' || role_name=='Nabell.com Registered Guest'? 
                    <TableRow key={customer_id}>

                        <TableCell scope="row">{email}</TableCell>
                        <TableCell scope="row">{first_name}</TableCell>
                        <TableCell scope="row">{last_name}</TableCell>
                        <TableCell scope="row">{role_name}</TableCell>
                        <TableCell scope="row">{login_count}</TableCell>
                        <TableCell>{null == last_login ? "":Moment(last_login).format('YYYY-MM-DD HH:mm')}</TableCell> 
                        <TableCell> <SafeAnchor onClick={() => this.statusChange(customer_id, modified_by,role_name=='Nabell.com Visitor' || role_name=='Nabell.com Registered Guest'?true:false)} >
                            &nbsp;<i className={(is_active) ? `fa fa-toggle-on fa-2x success` : `fa fa-toggle-off fa-2x red`}></i>
                        </SafeAnchor>
                        </TableCell>
                        <TableCell scope="row">
                            <div style={{'display':"flex"}}>
                            <Icon
                                 data-tip data-for="edit"
                                className="icons"
                                onClick={() => 
                                    this.setState({ editcustomerModalShow: true,
                                    customer: data,
                                    update: true
                                 })}
                            >edit
                                        </Icon>
                                        <StyledReactTooltip id="edit" effect="solid">
                                         <span>Edit</span>
                                        </StyledReactTooltip>
                            <Icon data-tip data-for="delete" onClick={() =>this.deleteCustomer(customer_id, modified_by)}>delete</Icon>
                            <StyledReactTooltip id="delete" effect="solid">
                                         <span>delete</span>
                                        </StyledReactTooltip>
                            </div>
                        </TableCell>
                    </TableRow>
                    :
                    <TableRow  key={customer_id}>

                    <TableCell className="guestexceed" scope="row">{email}</TableCell>
                    <TableCell className="guestexceed" scope="row">{first_name}</TableCell>
                    <TableCell className="guestexceed" scope="row">{last_name}</TableCell>
                    <TableCell className="guestexceed" scope="row">{role_name}</TableCell>
                    <TableCell className="guestexceed" scope="row">{login_count}</TableCell>
                    <TableCell  className="guestexceed">{null == last_login ? "":Moment(last_login).format('YYYY-MM-DD HH:mm')}</TableCell> 
                    <TableCell  > <SafeAnchor onClick={() => this.statusChange(customer_id, modified_by,false)} >
                        &nbsp;<i className={(is_active) ? `fa fa-toggle-on fa-2x success` : `fa fa-toggle-off fa-2x red`}></i>
                    </SafeAnchor>
                    </TableCell>
                    <TableCell   scope="row">
                        <div style={{'display':"flex"}}>
                        <Icon
                            className="icons"
                            onClick={() => 
                                this.setState({ editcustomerModalShow: true,
                                customer: data,
                                update: true
                             })}
                        >edit
                                    </Icon>
                        <Icon onClick={() =>this.deleteCustomer(customer_id, modified_by)}>delete</Icon>
                        </div>
                    </TableCell>
                </TableRow>
                )
            })
        }
    }

    handleChange = (e) => {

        this.setState({
            checked: e.target.checked
        })
    }

    render() {

        const mtt = {
            marginTop: '20px',
            marginTop: '20px',
            width:'100%',
            overflowX: 'auto'
        }


        let editModalClose = () => {
            this.setState({ editcustomerModalShow: false });
        }
        const tableGray = {
            background: '#f1f3f6',

        }
        
        return (
            <React.Fragment>

                <EditGuestCustomer
                    show={this.state.editcustomerModalShow}
                    onHide={editModalClose}
                    customer={this.state.customer}
                    update = {this.state.update}
                    updateList = {this.updateList}
                />
                <h2 style={{ 'clear': 'both', 'display': 'inline' }} className="heading">{this.state.pageheading}</h2>

                 <div style={mtt}>
                <Table  className="table-striped">

                    <TableHead style={tableGray}>
                        <TableRow>
                            {
                                <>
                                    <TableCell scope="row">User Name</TableCell>
                                    <TableCell scope="row">First Name</TableCell>
                                    <TableCell scope="row" >Last Name</TableCell>
                                    <TableCell scope="row">Role</TableCell>
                                    <TableCell scope="row">Login Count</TableCell>
                                    <TableCell >Last Login</TableCell>
                                    <TableCell >Status</TableCell>
                                    <TableCell scope="row">Action</TableCell>
                                </>
                            }

                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            this.renderTableData()
                        }
                    </TableBody>

                </Table>

                </div>

                <div className="custom-pagination">
                    <Pagination>
                        <Pagination.First onClick={e => this.handlePageClick(e, 1)} />
                        <Pagination.Prev onClick={e => this.handlePageClick(e, this.state.PageNumber > 1 ? this.state.PageNumber - 1 : this.state.PageNumber)} />
                        {this.renderPagination()}
                       
                        {/* <Pagination.Item>{10}</Pagination.Item> */}
                        <Pagination.Next onClick={e => this.handlePageClick(e, this.state.PageNumber < this.state.totalPages ? this.state.PageNumber + 1 : this.state.PageNumber)} />
                        <Pagination.Last onClick={e => this.handlePageClick(e, this.state.totalPages)} />
                    </Pagination>
                </div>
            </React.Fragment>
        )
    }
}


const mapStateToProps = state => {
    return {
        customers: state.manageCustomer.customers,
        totalPages: state.manageCustomer.totalPages
    }
};


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getGuestCustomerList: (data) => {
            return new Promise((resolve, reject) => {
                dispatch(getGuestCustomerList(data)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        changeStatus: (user_id,modified_by,nabell_id) => {
            return new Promise((resolve, reject) => {
                dispatch(toggleStatusCustomertItem(user_id, modified_by,nabell_id)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            })
        },

        deleteCustomer: (id, modified_by) => {
            return new Promise((resolve, reject) => {
                dispatch(deleteCustomerFromList(id, modified_by)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        }        
    }
}

ManageGuestCustomers = connect(mapStateToProps, mapDispatchToProps)(ManageGuestCustomers);

export default ManageGuestCustomers;