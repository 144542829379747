import Auth from '../../utils/Auth';
import { SERVER_ACTIONS } from '../../utils/Constants';
import HttpRequest from '../../utils/HttpRequest';
import { editProfile, signIn, editAdminProfile } from './AuthenticationActions';
import { calculatorStatus } from '../ManageCalculator/Components/CalculatorActions';
const auth = new Auth();

export function signInRequest(params, actions) {
    return (dispatch) => {
        new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.SIGN_IN, 'post', params).requestWithoutAuth().then(
            (response) => {
                auth.setUser(response.data.Data.userModel.first_name, response.data.Data.userModel.user_id, 
                    response.data.Data.userModel.email_id, false);
					auth.setIsPasswordReset(response.data.Data.userModel.password_reset);
                document.getElementById("loginusername").textContent = "Welcome " + response.data.Data.userModel.first_name;   
                //window.Message(`Welcome ${username}!`);
                auth.setAccessToken(response.data.Data.Token);
                
                const token = auth.getAccessToken();
                actions.setSubmitting(false);
                dispatch(signIn(true));
                return Promise.resolve(token);
            },
            (error) => {
              
                window.ShowResponseError(error);
               
                     actions.setSubmitting(false);
                   
                    return Promise.reject(error);
            });
    };
}


export function adminSignInRequest(params, actions) {
    actions.setSubmitting(true);
    return (dispatch) => {
        
        new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.ADMIN_SIGN_IN, 'post', params).requestWithoutAuth().then(
            (response) => {
                //console.log({ response })
                auth.setUser(response.data.Data.userModel.first_name, response.data.Data.userModel.user_id, 
                    response.data.Data.userModel.email_id, true);
                    document.getElementById("loginusername").textContent = "Welcome " + response.data.Data.userModel.first_name;
                //window.Message(`Welcome ${username}!`);
                auth.setAccessToken(response.data.Data.Token);
                
                const token = auth.getAccessToken();
                actions.setSubmitting(false);
                dispatch(signIn(true));
                return Promise.resolve(token);
            },
            (error) => {
                
                actions.setSubmitting(false);
                window.ShowResponseError(error);
                return Promise.reject(error);
            });
    };
}




export function registerCustomerRequest(params,actions) {
    actions.setSubmitting(true);
    return (dispatch) => {
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_REGISTER_PROFILE, 'post', params).requestWithAuth().then(
            (response) => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {

                    //window.Message(response.data, 'Success');
                    dispatch(signIn(false));

                    actions.setSubmitting(false);
                    return Promise.resolve(response.data);
                }
                return Promise.resolve(response.data);
            },
            (error) => {
                
                document.getElementById("loaderdiv").classList.add("d-none");
                window.Message(error.response.data, 'Error',true);
                 actions.setSubmitting(false);
                return Promise.reject(error);
            }
        );
    };
}

export function GuestLoginRequest(params, actions) {
    actions.setSubmitting(true);
    return (dispatch) => {
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_GUEST_LOGIN, 'post', params).requestWithoutAuth().then(
            (response) => {
                if (response.status === 200 && !response.data.errorCode) {
                    
                    //auth.setUser(response.data.first_name, response.data.user_id, response.data.email_id, false);
                    auth.setUser(response.data.Data.userModel.first_name, response.data.Data.userModel.user_id, 
                        response.data.Data.userModel.email_id, false);
                   auth.setAccessToken(response.data.Data.Token);

                    window.Message(response.data.Message, 'Success');
                    dispatch(signIn(true));
                    actions.setSubmitting(false);
                    return Promise.resolve(response.data);
                }
                return Promise.resolve(response.data);
            },
            (error) => {
                actions.setSubmitting(false);
                window.ShowResponseError(error);
                return Promise.reject(error);
            }
        );
    };
}

export function GuestRegisterRequest(params, actions) {
    actions.setSubmitting(true);
    return (dispatch) => {
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_GUEST_REGISTER, 'post', params).requestWithoutAuth().then(
            (response) => {
            document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.Message, 'success');
                    dispatch(signIn(false));
                    actions.setSubmitting(false);
                    return Promise.resolve(response);
                }
             
                return Promise.resolve(response);
            },
            (error) => {
                document.getElementById("loaderdiv").classList.add("d-none");
                actions.setSubmitting(false);
                window.ShowResponseError(error);
                return Promise.reject(error);
            }
        )
    }
}




export function sendAccessToken(params,actions) {
    return (dispatch) => {
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_GUEST_REGISTER, 'post', params).requestWithAuth().then(
            (response) => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.Message, 'success');
                    auth.setUser(response.data.Data.userModel.first_name, response.data.Data.userModel.user_id, 
                        response.data.Data.userModel.email_id, false);
                   auth.setAccessToken(response.data.Data.Token);
                    dispatch(signIn(true));

                    return Promise.resolve(response);
                }
                return Promise.resolve(response);
            },
            (error) => {
                document.getElementById("loaderdiv").classList.add("d-none");
                actions.setSubmitting(false);
                window.ShowResponseError(error);
                return Promise.reject(error);
                // window.ShowResponseError(error);
                // return Promise.reject(error);
            }
        )
    }
}


export function showProfileDataRequest(query) {

    return (dispatch) => {
        const queryString = (query && query.trim().length > 0) ? `?${query}` : '';
        return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_CUSTOMER_PROFILE_BY_ID}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                dispatch(editProfile(response.data));
                return Promise.resolve(response.data);
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    };
}


export function showAdminProfileDataRequest(query) {

    return (dispatch) => {
        const queryString = (query && query.trim().length > 0) ? `?${query}` : '';
        return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_ADMIN_PROFILE_BY_ID}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                dispatch(editAdminProfile(response.data));
                return Promise.resolve(response.data);
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    };

}


export function editProfileRequest(params) {
    return (dispatch) => {
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_UPDATE_CUSTOMER_PROFILE, 'post', params).requestWithAuth().then(
            (response) => {
                if (response.status === 200 && !response.data.errorCode) {
                    
                    window.Message(response.data.Message, 'Success');
                    // const accessToken = response.data.data && response.data.data[0].token;
                    const username = response.data.data && response.data.Data.first_name;
                    //auth.editProfile(accessToken);
                    
                    auth.setUser(response.data.Data.userModel.first_name, response.data.Data.userModel.customer_id, 
                        response.data.Data.userModel.email_id, false);
                   
                    //window.Message(`Welcome ${username}!`);
                    //auth.setAccessToken(response.data.Data.Token);
                    dispatch(editProfile(response.data.Data));

                    return Promise.resolve(response.data);
                }
                return Promise.resolve(response);
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    };
}

export function editAdminProfileRequest(params) {
    return (dispatch) => {
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_UPDATE_ADMIN_PROFILE, 'post', params).requestWithAuth().then(
            (response) => {
                if (response.status === 200 && !response.data.errorCode) {

                    window.Message(response.data.Message, 'Success');
                    // const accessToken = response.data.data && response.data.data[0].token;
                    const username = response.data.Data && response.data.Data.first_name;
                    //auth.editProfile(accessToken);
                    auth.setUser(username,response.data.Data.user_id,response.data.Data.email,true);
                    dispatch(editProfile(response.data.Data));

                    return Promise.resolve(response.data);
                }
                return Promise.resolve(response);
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    };
}

export function changePasswordRequest(params) {
    return (dispatch) => {
        return new HttpRequest(SERVER_ACTIONS.POST_CHANGE_PASSWORD, 'post', params).requestWithAuth().then(
            (response) => {
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.Message, 'Success');
                    // const accessToken = response.data.data && response.data.data[0].token;
                    // auth.changePassword(accessToken);
                    // dispatch(changePassword(false));
                    setTimeout(() => auth.logout(), 3000);
                    
                    return Promise.resolve(response);
                }
                return Promise.resolve(response);
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    };
}


export function ForgotPasswordRequest(params, actions) {
    return (dispatch) => {
        actions.setSubmitting(true);
        const queryString = '?email=' + params.email;
        return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.POST_FORGOT_PASSWORD}${queryString}`, 'post', {}).requestWithoutAuth().then(
            (response) => {
                actions.setSubmitting(false);
                if (response.status === 200 && !response.data.errorCode) {
                    //console.log(response.data)
                    

                    //dispatch(forgotPassword(response.data.data));
                    window.Message(response.data, 'Reset link sent to your email');

                    return Promise.resolve(response.data);
                }
                return Promise.resolve(response.data);
            },
            (error) => {
                window.Message(error.response.data.Message, 'Error',true);
              //  window.ShowResponseError(error.response.data.Message);
              actions.setSubmitting(false);
                return Promise.reject(error);
            }
        );
    };
}
export function SetPasswordRequest(params, actions) {
    return () => {
        const queryString = '?newpassword=' + params.password +'&token='+params.token.token ;
        
        return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.POST_SET_PASSWORD}${queryString}`, 'post', {}).requestWithoutAuth().then(
            (response) => {
        
                if (response.status === 200 && !response.data.errorCode) {
                    //console.log(response.data)
                    return Promise.resolve(response);
                }
                return Promise.resolve(response.data);
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    };
}


export async function getURLAccessible(CurrentURL) {

    const queryString =  '?CurrentURL='+CurrentURL;
    return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_IS_URL_ACCESSIBLE}${queryString}`, 'get', {})
        .requestWithAuth()
        .then(response => {
            
            if (response.status === 200) {
            // dispatch(updatePartDetailsData(response.data.data));
            return Promise.resolve(response.data);
            }
            else{
                return Promise.reject(response.data);
            }
        }, error => {
            return Promise.reject(error);
        });

}

export function ForgotAdminPasswordRequest(params, actions) {
    return (dispatch) => {
        actions.setSubmitting(true);
        const queryString = '?email=' + params.email;
        return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.POST_ADMIN_FORGOT_PASSWORD}${queryString}`, 'post', {}).requestWithoutAuth().then(
            (response) => {
                actions.setSubmitting(false);
                if (response.status === 200 && !response.data.errorCode) {
                    //console.log(response.data)
                    //dispatch(forgotPassword(response.data.data));
                    window.Message(response.data, 'Reset link sent to your email');

                    return Promise.resolve(response.data);
                }
                return Promise.resolve(response.data);
            },
            (error) => {
                window.Message(error.response.data.Message, 'Error',true);
              //  window.ShowResponseError(error.response.data.Message);
              actions.setSubmitting(false);
                return Promise.reject(error);
            }
        );
    };
}
export function SetAdminPasswordRequest(params, actions) {
    return () => {
        const queryString = '?newpassword=' + params.password +'&token='+params.token.token ;
        
        return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.POST_SET_ADMIN_PASSWORD}${queryString}`, 'post', {}).requestWithoutAuth().then(
            (response) => {
        
                if (response.status === 200 && !response.data.errorCode) {
                    //console.log(response.data)
                    return Promise.resolve(response);
                }
                return Promise.resolve(response.data);
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    };
}